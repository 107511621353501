import React from "react";
import { Container } from "@atoms";
import { TestimonialCard, CaseStudyCard } from "@molecules";

const ImpactGrid = ({ content }) => {
  let testimonial = 0;
  return (
    <Container>
      <ul className="flex flex-wrap gap-5">
        {content.map(c => {
          switch (c.type) {
            case "testimonials":
              testimonial += 1;
              return (
                <li
                  key={c.uid}
                  className="flex-grow md:flex-[0_1_calc(50%-1.25rem)]"
                >
                  <TestimonialCard
                    {...c}
                    color={testimonial % 2 === 0 ? "purple" : "teal"}
                    padding={false}
                    small
                  />
                </li>
              );
            case "caseStudies":
              return (
                <li
                  key={c.uid}
                  className="flex-grow md:flex-[0_1_calc(50%-1.25rem)]"
                >
                  <CaseStudyCard {...c} />
                </li>
              );
            default:
              return null;
          }
        })}
      </ul>
    </Container>
  );
};

export default ImpactGrid;
